@darkfont: #dadada;
@darkblack: #141414;
@darklink: #41e4ae;

@lightfont: #121212; 
@lightbg: #fff;
@lightlink: #115f92;
@lightlinkvisited: #115f92;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Color scheme */
.lightTheme * {
	color: @lightfont;
	background-color: #fff;
	:link { color: @lightlink; }
	:visited { color: @lightlinkvisited; }
	textarea {
		background: rgba(255, 255, 100, .075);
		box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .2), inset -1px -1px 0 rgba(0, 0, 0, .04);
		max-width: 80ch;
		white-space: normal;
	}
}
.darkTheme * {
	color: #dadada;
	background-color: @darkblack;
	:link {color: @darklink; }
	:visited {color: @darklink; }
	textarea {
		background: rgba(109, 230, 183, 0.5);
		box-shadow: inset 1px 1px 0 rgba(255, 255, 255, .2), inset -1px -1px 0 rgba(255, 255, 255, .04);
		color: white;
		max-width: 80ch;
		white-space: normal;
	}

}
body::-webkit-scrollbar {
	width: .5em;
}

.slides::-webkit-scrollbar {
	width: 0px;
}
   
body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
	border-radius: 10%/50%;
}

body {
	font-size: 18px;
	font-family: system-ui, sans-serif;
	line-height: 1.4;
	color: var(--textcolor);
	background: var(--bgcolor);
	position: relative;
	overflow-y: scroll;
	//max-width: 64em;
	/* remove this for a full-width layout */
	margin: 0 auto;
	/* centers the layout */
}

/* ------- Sections ------- */

section {
	padding: calc(6em + 2vw) 5vw 8vw 5vw;
	/* ! Everything below is needed ! */
	display: none;
	//position: absolute;
	//top: 0;
	min-height: 100vh;
	//width: 100%;
	background: var(--bgcolor);
}

section, section:target ~ section:last-of-type {
	display: none;
}
  section:target, section:last-of-type {
	display: inherit;
}

section:target {
	/* Show section */
	display: block;
}

/* ------- Header ------- */

header {
	padding: 2vw 5vw 0 5vw;
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	width: 100%;
	z-index: 2;
}

header h1 {
	font-size: 1em;
	flex: 1;
	/* pushes nav to the right */
	white-space: nowrap;
	padding: 0 5vw .5em 0;
}

nav a:not(:last-of-type) {
	margin-right: 1.5vw;
}

/* ------- Footer ------- */

footer {
	position: fixed;
	display: block;
	bottom: 0vh;
	width: 100vw;
	height: 5vh;
	padding: 1vh;
	margin: 0 auto;
	text-align: center;
	z-index: 2;
}

/* ------- Smaller screens ------- */

@media only screen {
	@media (-webkit-min-device-pixel-ratio: 3) {
		h1 * {
			font-size: 1.6em;
		}

		h2, h3, nav * {
			font-size: 1.4em;
		}
		p, nav, div, section, article {
			font-size: 1.2em;
		}
		header > nav { 
			display: flex;
			position: fixed;
			padding: 1vh 2vw;
			bottom: 0vh;
			left: 0vh;
			right: 0vh;
			width: 100vw;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
		}
		footer {
			display: none;
		}
		.slides {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 100vh;
			overflow-y: scroll;
			scroll-snap-type: y mandatory;
		}
		
		.slides figure {
			height: 100vh;
			padding: 0 5vw;
			margin: 0;
			display: grid;
			place-items: center;
			align-content: center;
			scroll-snap-align: center;
		}
		
		.slides figure img {
			max-height: 88vh;
			/* Adjust if needed */
		}
	}
	@media (max-width: 812px) {
		header > nav { 
			display: flex;
			position: fixed;
			padding: 1vh 2vw;
			bottom: 0vh;
			left: 0vh;
			right: 0vh;
			width: 100vw;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
		}
		footer {
			display: none;
		}
	}
}

footer h1 {
	font-size: 1em;
	flex: 1;
	/* pushes nav to the right */
	white-space: nowrap;
	padding: 0 5vw .5em 0;
}

/* ------- General ------- */
button {
	padding: 1vw;
	font: inherit;
}
a {
	text-decoration: none;
	color: var(--highlight);
}

a:hover {
	border-bottom: 1px solid;
}

section h1 {
	font-size: 1em;
	margin: 0 0 1em 0;
}

h2,
h3,
h4 {
	font-size: 1em;
	font-weight: 600;
	margin: 1.6em 0 .6em 0;
}

p,
ul,
ol,
article {
	max-width: 80ch;
	/* Limit line-length to 80 characters */
	margin-bottom: .6em;
}

ul {
	list-style-type: none;
}

ul li::marker {
	content: "\2022   ";
}

li {
	margin-bottom: .2em;
}

ul,
ol {
	padding-left: 2ch;
}

b,
strong {
	font-weight: 600;
}

small {
	font-size: .85em;
}

hr {
	height: 1px;
	border: 0;
	background: currentColor;
	opacity: .1;
	margin: 1.2em 0;
}

abbr {
	text-decoration: none;
}

abbr[title]:hover {
	opacity: .7;
	cursor: help;
}

blockquote {
	padding-left: 2ch;
	opacity: .7;
	margin-bottom: .6em;
	position: relative;
}

blockquote:before {
	content: "";
	position: absolute;
	left: 0;
	top: .3em;
	bottom: .3em;
	background: currentColor;
	width: 1px;
	opacity: .2;
}

img,
svg,
video,
audio {
	display: block;
	max-width: 100%;
	height: auto;
	fill: currentColor;
}

code,
textarea {
	font-family: ui-monospace, SF Mono, Menlo, Monaco, Andale Mono, monospace;
	font-size: 1em;
	opacity: .7;
}

a code {
	opacity: 1;
}

pre,
textarea {
	/* for code samples */
	font-size: .9em;
	color: inherit;
	line-height: inherit;
	padding: .6em .9em;
	margin: .8em 0 1em 0;
	position: relative;
	display: block;
	width: 100%;
	white-space: pre;
	border: 0;
	border-radius: 4px;
}

/* Inline footnotes */

label {
	cursor: pointer;
	vertical-align: super;
	line-height: 1;
	font-size: .75em;
	padding-left: .1em;
}

label:hover {
	color: var(--highlight);
}

label:before {
	content: "[";
}

label:after {
	content: "]";
}

label+input,
label+input+small {
	display: none;
}

input:checked+small {
	display: block;
	padding: .8em 0 1em 2.5vw;
	;
}

/* Figures */

figure {
	margin: 2em 0 1.5em 0;
}

figure figcaption {
	margin: 0.8em 0 0 0;
	font-size: .85em;
	opacity: .7;
}

/* Responsive video embeds */

figure.video {
	position: relative;
	padding-bottom: 56.25%;
	/* 16:9 */
	height: 0;
	overflow: hidden;
}

figure.video iframe,
figure.video object,
figure.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	visibility: hidden;
	/* so loading="lazy" should work? */
}

section:target iframe {
	visibility: visible;
}

/* External links */

a[href*="//"]:after {
	font-weight: 300;
	font-size: .85em;
	content: "\2197";
	/* top right arrow: â†— */
	color: var(--textcolor);
	opacity: .25;
}

a[href*="//"]:hover:after {
	color: var(--highlight);
	opacity: 1;
}

/* File links */

a:before {
	font-size: .7em;
	margin-right: .4em;
}

/* Add more filetypes here if you want */

a[href$=".pdf"]:before {
	content: "PDF";
}

a[href$=".txt"]:before {
	content: "TXT";
}

a[href$=".mp3"]:before {
	content: "MP3";
}

a[href$=".zip"]:before {
	content: "ZIP";
}

a[href$=".rar"]:before {
	content: "RAR";
}

a[href$=".jpeg"]:before,
a[href$=".jpg"]:before,
a[href$=".gif"]:before,
a[href$=".png"]:before {
	content: "IMG";
}

/* ------- notes ------- */

article+article {
	margin-top: 4.5em;
}

article h2 {
	font-weight: 700;
	margin: 0 0 1em 0;
}

article time {
	margin-left: .6em;
	font-size: .8em;
	font-weight: 400;
	opacity: .7;
}

/* ------- Images Grid ------- */

.grid {
	display: grid;
	grid-gap: 5vmin;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	/* 150px = minimum image size */
	grid-auto-flow: dense;
	padding: 2em 0;
}

.grid a {
	position: relative;
	border: 0;
}

.grid a:hover {
	transform: scale(.975);
}

/* For a square ratio */
.grid a:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.grid a img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

/* ------- Slideshow ------- */

.slides {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
}

.slides figure {
	height: 100vh;
	padding: 0 5vw;
	margin: 0;
	display: grid;
	place-items: center;
	align-content: center;
	scroll-snap-align: center;
}

.slides figure img {
	max-height: 77vh;
	/* Adjust if needed */
}

.slides figure.cover {
	padding: 0;
}

.slides figure.cover img {
	max-height: none;
	position: absolute;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}
.slides figure.cover p {
	z-index: 1;
}

/* ------- Lightbox ------- */

.lightbox {
	display: none;
	color: var(--textcolor);
}

.lightbox:target {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: grid;
	place-items: center;
	align-content: center;
	border: 0;
	z-index: 3;
}

.lightbox img {
	max-height: 100vh;
	z-index: 4;
}

.lightbox:target:before {
	/* Loading spinner */
	content: "";
	height: 2em;
	width: 2em;
	animation: spin .8s infinite linear;
	border: 1px solid;
	border-right-color: transparent;
	border-radius: 50%;
	display: block;
	position: absolute;
	transform: translateX(-50%);
	opacity: .25;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.lightbox:target:after {
	/* Ã— to close */
	content: "\00D7";
	position: fixed;
	font-size: 2em;
	font-weight: 200;
	line-height: 0;
	top: .75em;
	right: .5em;
	z-index: 4;
}

/* ------- Print ------- */

@media print {

	nav,
	.lightbox:target:after {
		display: none;
	}

	article,
	figure,
	img {
		page-break-inside: avoid;
		break-inside: avoid;
	}

	/* Inline footnotes */

	label+input+small {
		display: inline;
	}

	label+input+small:before {
		content: "[";
	}

	label+input+small:after {
		content: "]";
	}

	/* Slides */

	.slides,
	.slides figure {
		position: relative;
		height: auto;
		padding: 0;
	}

	.slides figure img,
	.slides figure.cover img {
		max-height: auto;
		position: relative;
		z-index: 1;
		width: auto;
		height: 100vh;
		object-fit: contain;
	}

}